<template>
  <v-container class="container--fluid grid-list-md">
    <v-row no-gutters>
      <v-col cols="12">
        <v-card class="mx-auto" outlined>
          <ListSkeleton v-if="listSkeleton" />
          <v-card-text class="text-h5 pa-0" v-else>
            <v-data-table
              :height="
                supplierReportList &&
                supplierReportList.length > 0 &&
                showHeaders.length > 0
                  ? '470'
                  : ''
              "
              :style="{ maxHeight: !supplierReportList ? '340px' : '' }"
              :dense="true"
              class="custom-table mb-0 ma-2 row-pointer"
              :headers="showHeaders"
              :items="supplierReportList"
              style="word-break: break-word"
              :search="search"
              item-class="py-4"
              fixed-header
              :loading="isProgressCircular"
              :items-per-page="limitData"
              :page.sync="page"
              @pagination="newPagination"
              @dblclick:row="doubleClick"
              @page-count="pageCount = $event"
              must-sort
            >
              <template v-slot:loading>
                <v-overlay>
                  <v-icon size="64" class="hourglass-icon" color="primary"
                    >mdi-timer-sand-paused</v-icon
                  >
                </v-overlay>
              </template>
              <template v-slot:[`item.id`]="{ item }">
                <span :ref="`row-${item.id}`">{{ item.id }}</span>
              </template>
              <template v-slot:[`item.supplier_opening_date`]="{ item }">
                <span>{{
                  profileById.date_format
                    ? parseDateYYYYMMDD(item.supplier_opening_date)
                    : formatDateDDMMYYYY(item.supplier_opening_date)
                }}</span>
              </template>
              <template v-slot:[`item.supplier_end_date`]="{ item }">
                <span>{{
                  profileById.date_format
                    ? parseDateYYYYMMDD(item.supplier_end_date)
                    : formatDateDDMMYYYY(item.supplier_end_date)
                }}</span>
              </template>
              <template v-slot:top>
                <v-card-title class="py-1 primary--text">
                  <v-icon color="primary">mdi-filter-cog-outline</v-icon>
                  <h6 class="ms-2 font-weight-regular">
                    {{ $t("supplier.filters") }}
                  </h6>
                  <v-spacer />
                  <HtmlToPdf
                    class="mx-1 mb-1"
                    :disabled="supplierReportList.length"
                    :model="supplierReportList"
                    :payload="payload"
                    type="SupplierReportPDF"
                  />
                  <ExcelAndResetFilterCommon
                    :data="supplierReportList.length"
                    @downloadExcelFile="downloadExcelFile"
                    @resetFilters="resetFilters"
                  />
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-row dense>
                    <v-col cols="3">
                      <v-autocomplete
                        hide-details="auto"
                        v-model="selectedSupplierGroup"
                        :items="supplierGroupValue"
                        item-text="description"
                        item-value="id"
                        clearable
                        flat
                        @input="buildUrl"
                        return-object
                        :label="$t('supplier.field.group')"
                      />
                    </v-col>
                    <v-col cols="3">
                      <v-select
                        hide-details="auto"
                        v-model="selectedStatus"
                        :items="statusValue"
                        item-text="description"
                        item-value="id"
                        return-object
                        clearable
                        flat
                        :menu-props="{ bottom: true, offsetY: true }"
                        @input="buildUrl"
                        :label="$t('supplier.field.status')"
                      />
                    </v-col>
                    <v-col cols="3">
                      <v-select
                        hide-details="auto"
                        v-model="selectedPayment"
                        :items="termsOfPaymentValue"
                        item-text="description"
                        item-value="id"
                        return-object
                        clearable
                        flat
                        :menu-props="{ bottom: true, offsetY: true }"
                        @input="buildUrl"
                        :label="$t('supplier.field.termsOfPayment')"
                      />
                    </v-col>
                    <v-col cols="3">
                      <v-select
                        hide-details="auto"
                        v-model="selectedRegion"
                        :items="regionValue"
                        item-text="description"
                        item-value="id"
                        return-object
                        clearable
                        flat
                        :menu-props="{ bottom: true, offsetY: true }"
                        @input="buildUrl"
                        :label="$t('supplier.field.area')"
                      />
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12" class="text-center mt-3">
                      <ExecuteAndRestCommon
                        @reset="reset"
                        @paginate="paginate(false)"
                        type="Supplier"
                      />
                      <v-dialog
                        dense
                        v-model="dialog"
                        scrollable
                        max-width="500px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            small
                            color="primary"
                            class="float-end"
                            outlined
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon left> mdi-view-comfy </v-icon>
                            {{ $t("supplier.fieldView") }}
                          </v-btn>
                        </template>
                        <v-card>
                          <v-row class="align-center ma-0">
                            <v-col>
                              <v-card-title class="primary--text">
                                <v-icon left color="primary"
                                  >mdi-land-fields</v-icon
                                >
                                <h6 class="font-weight-regular">
                                  {{ $t("supplier.selectFields") }}
                                </h6>
                              </v-card-title>
                            </v-col>
                            <v-col>
                              <v-btn
                                x-small
                                color="primary"
                                class="float-end mx-3"
                                @click="updateHeader"
                                >{{ $t("supplier.update") }}</v-btn
                              >
                            </v-col>
                          </v-row>
                          <v-divider />
                          <v-card-text style="height: 300px">
                            <v-select
                              dense
                              class="mt-2"
                              hide-details="auto"
                              v-model="selectedHeaders"
                              :items="headers"
                              item-text="text"
                              item-value="id"
                              return-object
                              clearable
                              multiple
                              outlined
                              :menu-props="{
                                bottom: true,
                                offsetY: true,
                                maxHeight: 200,
                                overflowY: true,
                              }"
                              :label="$t('supplier.selectFields')"
                            >
                              <template v-slot:prepend-item>
                                <v-list-item @mousedown.prevent @click="toggle">
                                  <v-list-item-action>
                                    <v-checkbox dense v-model="selectFields" />
                                  </v-list-item-action>
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      {{ $t("supplier.selectAll") }}
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-divider class="mt-2" />
                              </template>
                            </v-select>
                          </v-card-text>
                        </v-card>
                      </v-dialog>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-title class="py-1 primary--text">
                  <v-row dense no-gutters>
                    <v-col cols="9" class="d-flex">
                      <v-icon color="primary">mdi-view-list-outline</v-icon>
                      <h6 class="ms-2 align-self-center font-weight-regular">
                        {{ $t("supplier.debitReport") }}
                      </h6>
                    </v-col>
                    <v-col cols="3">
                      <v-text-field
                        ref="search"
                        :full-width="false"
                        :dense="true"
                        hide-details="auto"
                        :autofocus="type ? true : false"
                        v-model="search"
                        @input="buildUrl"
                        append-icon="mdi-magnify"
                        :label="$t('common.search')"
                        single-line
                      />
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-divider />
              </template>
              <template v-slot:[`footer.prepend`]>
                <v-row class="d-flex">
                  <v-col class="mx-auto" cols="12">
                    <span class="pl-3 pt-2"
                      >{{ $t("common.numberOfTotalRows") }}
                      {{
                        supplierReportList &&
                        supplierReportList.length &&
                        showHeaders.length > 0
                          ? numberOfRows
                          : 0
                      }}</span
                    >
                  </v-col>
                </v-row>
              </template>
              <template v-slot:[`footer.page-text`]>
                <v-row class="d-flex">
                  <v-col class="mx-auto mr-16" cols="4">
                    <v-container class="w-100">
                      <v-pagination
                        :total-visible="5"
                        v-model="page"
                        :length="pageCount"
                      ></v-pagination>
                    </v-container>
                  </v-col>
                </v-row>
              </template>
              <template v-slot:no-data>
                <v-card-text class="text-caption text-center">{{
                  $t("common.noDataFound")
                }}</v-card-text>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { getAuxiliaryZtableValueByName } from "@/utils";
import ListSkeleton from "@/components/skeleton/ListSkeleton";
import exportFromJSON from "export-from-json";
import HtmlToPdf from "@/components/generatePDF/HtmlToPdf";
import { formatDateDDMMYYYY, parseDateYYYYMMDD } from "@/utils";
import ExcelAndResetFilterCommon from "@/components/ExcelAndResetFilterCommon";
import ExecuteAndRestCommon from "@/components/ExecuteAndRestCommon";

export default {
  name: "SupplierReport",
  props: ["type"],
  components: {
    ListSkeleton,
    HtmlToPdf,
    ExcelAndResetFilterCommon,
    ExecuteAndRestCommon,
  },
  data() {
    return {
      sortByValue: null,
      sortDescValue: null,
      supplierLimit: 10,
      selectedSupplierGroup: null,
      selectedRegion: null,
      supplierGroupValue: [],
      regionValue: [],
      termsOfPaymentValue: [],
      listSkeleton: false,
      selectedStatus: null,
      selectedPayment: null,
      statusValue: [],
      dialog: false,
      selectedHeaders: [],
      selectFields: false,
      search: this.$route.query.search || "",
      limitData: this.limit,
      limit: this.pageChange?.limit ? Number(this.pageChange?.limit) : 10,
      page: this.pageChange?.page ? Number(this.pageChange?.page) : 1,
      pagination: null,
      numberOfRows: "",
      custom: { id: 111111111111111, description: this.$t("invoice.empty") },
      isProgressCircular: false,
    };
  },
  computed: {
    params() {
      return {
        query: this.search,
        supplier_group_id: this.selectedSupplierGroup?.id,
        region_group_id: this.selectedRegion?.id,
        supplier_id: this.selectedSupplier?.id,
        selected_status_id: this.selectedStatus?.id,
        selected_payment_id: this.selectedPayment?.id,
      };
    },
    payload() {
      return {
        supplier_group_id: this.selectedSupplierGroup?.description,
        region_group_id: this.selectedRegion?.description,
        selected_status_id: this.selectedStatus?.description,
        selected_payment_id: this.selectedPayment?.description,
      };
    },
    selectAllHeaders() {
      return this.selectedHeaders.length === this.headers.length;
    },
    ...mapGetters({
      supplierReportList: "supplier/supplierReportList",
      locale: "locale",
      showHeaders: "supplierReport/showHeaders",
      accessRight: "accessRight",
      pageChange: "supplierReport/pageChange",
      profileById: "profile/profileById",
      rowIndex: "supplier/rowIndex",
    }),
    headers() {
      return [
        {
          text: this.$t("supplier.id"),
          value: "id",
          width: "130px",
          align: "start",
          class: "px-1",
          sortable: true,
        },
        {
          text: this.$t("supplier.supplierName"),
          value: "name_1",
          width: "140px",
          align: "start",
          class: "px-2",
          sortable: true,
        },
        {
          text: this.$t("supplier.field.email"),
          value: "email",
          width: "160px",
          align: "start",
          class: "px-2",
          sortable: true,
        },
        {
          text: this.$t("supplier.field.group"),
          value: "group.description",
          width: "160px",
          align: "start",
          class: "px-2",
          sortable: true,
        },
        {
          text: this.$t("supplier.field.status"),
          value: "status.description",
          width: "150px",
          align: "start",
          class: "px-2",
          sortable: true,
        },
        {
          text: this.$t("supplier.field.termsOfPayment"),
          value: "payment_terms_id",
          width: "180px",
          align: "start",
          class: "px-2",
          sortable: true,
        },
        {
          text: this.$t("supplier.field.supplierOpeningDate"),
          value: "supplier_opening_date",
          width: "180px",
          align: "start",
          class: "px-2",
          sortable: true,
        },
        {
          text: this.$t("supplier.field.supplierEndDate"),
          value: "supplier_end_date",
          width: "180px",
          align: "start",
          class: "px-2",
          sortable: true,
        },
        {
          text: this.$t("supplier.field.area"),
          value: "region_id",
          width: "50px",
          align: "start",
          class: "px-2",
          sortable: true,
        },
        {
          text: this.$t("supplier.field.address1"),
          value: "address_1",
          width: "180px",
          align: "start",
          class: "px-2",
          sortable: true,
        },
        {
          text: this.$t("supplier.field.postalCode1"),
          value: "postal_code_1",
          width: "140px",
          align: "start",
          class: "px-2",
          sortable: true,
        },
        {
          text: this.$t("supplier.field.pobox1"),
          value: "po_box_1",
          width: "140px",
          align: "start",
          class: "px-2",
          sortable: true,
        },
        {
          text: this.$t("supplier.field.town1"),
          value: "town_1",
          width: "140px",
          align: "start",
          class: "px-2",
          sortable: true,
        },
        {
          text: this.$t("supplier.field.state"),
          value: "state",
          width: "140px",
          align: "start",
          class: "px-2",
          sortable: true,
        },
        {
          text: this.$t("supplier.field.country"),
          value: "country",
          width: "140px",
          align: "start",
          class: "px-2",
          sortable: true,
        },
        {
          text: this.$t("supplier.supplierName2"),
          value: "name_2",
          width: "140px",
          align: "start",
          class: "px-2",
          sortable: true,
        },
        {
          text: this.$t("supplier.field.address2"),
          value: "address_2",
          width: "180px",
          align: "start",
          class: "px-2",
          sortable: true,
        },
        {
          text: this.$t("supplier.field.postalCode2"),
          value: "postal_code_2",
          width: "140px",
          align: "start",
          class: "px-2",
          sortable: true,
        },
        {
          text: this.$t("supplier.field.pobox2"),
          value: "po_box_2",
          width: "140px",
          align: "start",
          class: "px-2",
          sortable: true,
        },
        {
          text: this.$t("supplier.field.town2"),
          value: "town_2",
          width: "140px",
          align: "start",
          class: "px-2",
          sortable: true,
        },
        {
          text: this.$t("supplier.field.site"),
          value: "site",
          width: "140px",
          align: "start",
          class: "px-2",
          sortable: true,
        },
        {
          text: this.$t("supplier.field.authorizedDealerNo"),
          value: "authorized_dealer_no",
          width: "180px",
          align: "start",
          class: "px-2",
          sortable: true,
        },
        {
          text: this.$t("supplier.field.bookKipingNo"),
          value: "book_kiping_no",
          width: "140px",
          align: "start",
          class: "px-2",
          sortable: true,
        },
        {
          text: this.$t("supplier.field.warningNote"),
          value: "warning_note",
          width: "140px",
          align: "start",
          class: "px-2",
          sortable: true,
        },
        {
          text: this.$t("supplier.field.generalRemarks"),
          value: "general_remarks",
          width: "140px",
          align: "start",
          class: "px-2",
          sortable: true,
        },
        {
          text: this.$t("supplier.fax"),
          value: "fax",
          width: "140px",
          align: "start",
          class: "px-2",
          sortable: true,
        },
        {
          text: this.$t("supplier.field.dialingArea0"),
          value: "area_prefix_1",
          width: "140px",
          align: "start",
          class: "px-2",
          sortable: true,
        },
        {
          text: this.$t("supplier.field.dialingArea1"),
          value: "area_prefix_2",
          width: "140px",
          align: "start",
          class: "px-2",
          sortable: true,
        },
        {
          text: this.$t("supplier.field.dialingArea2"),
          value: "area_prefix_3",
          width: "140px",
          align: "start",
          class: "px-2",
          sortable: true,
        },
        {
          text: this.$t("supplier.field.dialingArea3"),
          value: "area_prefix_4",
          width: "140px",
          align: "start",
          class: "px-2",
          sortable: true,
        },
        {
          text: this.$t("supplier.field.dialingArea4"),
          value: "area_prefix_5",
          width: "140px",
          align: "start",
          class: "px-2",
          sortable: true,
        },
        {
          text: this.$t("supplier.field.dialingArea5"),
          value: "area_prefix_6",
          width: "140px",
          align: "start",
          class: "px-2",
          sortable: true,
        },
        {
          text: this.$t("supplier.field.cellular1"),
          value: "cellular_1",
          width: "140px",
          align: "start",
          class: "px-2",
          sortable: true,
        },
        {
          text: this.$t("supplier.field.cellular2"),
          value: "cellular_2",
          width: "140px",
          align: "start",
          class: "px-2",
          sortable: true,
        },
        {
          text: this.$t("supplier.field.countryPrefix0"),
          value: "country_prefix_1",
          width: "140px",
          align: "start",
          class: "px-2",
          sortable: true,
        },
        {
          text: this.$t("supplier.field.countryPrefix1"),
          value: "country_prefix_2",
          width: "140px",
          align: "start",
          class: "px-2",
          sortable: true,
        },
        {
          text: this.$t("supplier.field.countryPrefix2"),
          value: "country_prefix_3",
          width: "140px",
          align: "start",
          class: "px-2",
          sortable: true,
        },
        {
          text: this.$t("supplier.field.countryPrefix3"),
          value: "country_prefix_4",
          width: "140px",
          align: "start",
          class: "px-2",
          sortable: true,
        },
        {
          text: this.$t("supplier.field.countryPrefix4"),
          value: "country_prefix_5",
          width: "140px",
          align: "start",
          class: "px-2",
          sortable: true,
        },
        {
          text: this.$t("supplier.field.countryPrefix5"),
          value: "country_prefix_6",
          width: "140px",
          align: "start",
          class: "px-2",
          sortable: true,
        },
        {
          text: this.$t("supplier.field.extension0"),
          value: "extension_1",
          width: "140px",
          align: "start",
          class: "px-2",
          sortable: true,
        },
        {
          text: this.$t("supplier.field.extension1"),
          value: "extension_2",
          width: "140px",
          align: "start",
          class: "px-2",
          sortable: true,
        },
        {
          text: this.$t("supplier.field.extension2"),
          value: "extension_3",
          width: "140px",
          align: "start",
          class: "px-2",
          sortable: true,
        },
        {
          text: this.$t("supplier.field.phones0"),
          value: "phone_1",
          width: "140px",
          align: "start",
          class: "px-2",
          sortable: true,
        },
        {
          text: this.$t("supplier.field.phones1"),
          value: "phone_2",
          width: "140px",
          align: "start",
          class: "px-2",
          sortable: true,
        },
        {
          text: this.$t("supplier.field.phones2"),
          value: "phone_3",
          width: "140px",
          align: "start",
          class: "px-2",
          sortable: true,
        },
        {
          text: this.$t("supplier.field.createdAt"),
          value: "created_at",
          width: "180px",
          align: "start",
          class: "px-2",
          sortable: true,
        },
        {
          text: this.$t("supplier.field.updatedAt"),
          value: "updated_at",
          width: "180px",
          align: "start",
          class: "px-2",
          sortable: true,
        },
      ];
    },
    empty() {
      return this.$t("invoice.empty");
    },
  },
  created() {
    if (this.showHeaders.length == 0) {
      this.$store.commit("supplierReport/SHOW_HEADERS", this.headers);
    }
    this.page = this.pageChange.page;
    this.limit = this.pageChange.limit;
    this.limitData = this.limit;
  },
  beforeDestroy() {
    this.$store.commit("supplierReport/SHOW_PAGE_CHANGE", {
      page: this.pagination.page,
      limit: this.pagination.itemsPerPage,
    });
  },
  destroyed() {
    if (!this.$route.params.supplier_id) {
      this.$store.commit("supplier/SET_SUPPLIER_REPORT", []);
    }
  },
  watch: {
    empty() {
      this.custom.description = this.empty;
    },
    pagination: {
      handler(newVal) {
        if (this.pagination) {
          this.limit = newVal.itemsPerPage ? newVal.itemsPerPage : 10;
        }
      },
      deep: true,
    },
    type: {
      handler() {
        this.search = "";
      },
    },
    "$route.query": {
      handler(newVal, oldVal) {
        if (
          JSON.stringify(newVal) !== JSON.stringify(oldVal) ||
          !Object.keys(this.$route.query).length
        )
          this.search = this.$route.query.search || "";
      },
    },
    selectedHeaders() {
      if (this.selectedHeaders.length === this.headers.length) {
        this.selectFields = true;
      } else {
        this.selectFields = false;
      }
    },
    locale() {
      this.updateHeader();
    },
  },
  async mounted() {
    this.listSkeleton = true;
    this.selectedHeaders = this.showHeaders;
    this.page = this.pageChange.page;
    this.limit = this.pageChange.limit;
    this.limitData = this.limit;
    this.$store.dispatch("supplier/GetSupplier");
    getAuxiliaryZtableValueByName(4).then((tableSupplierValue) => {
      if (Object.keys(tableSupplierValue).length > 0) {
        this.supplierGroupValue = tableSupplierValue.ztables.sort((a, b) =>
          a.description.toLocaleLowerCase() > b.description.toLocaleLowerCase()
            ? 1
            : -1
        );
        this.supplierGroupValue = [this.custom, ...this.supplierGroupValue];
      }
    });
    getAuxiliaryZtableValueByName(5).then((statusTableNameValue) => {
      if (Object.keys(statusTableNameValue).length > 0) {
        this.statusValue = statusTableNameValue.ztables.sort((a, b) =>
          a.description.toLocaleLowerCase() > b.description.toLocaleLowerCase()
            ? 1
            : -1
        );
        this.statusValue = [this.custom, ...this.statusValue];
      }
    });
    getAuxiliaryZtableValueByName(7).then((termsOfPaymentTableNameValue) => {
      if (Object.keys(termsOfPaymentTableNameValue).length > 0) {
        this.termsOfPaymentValue = termsOfPaymentTableNameValue.ztables.sort(
          (a, b) =>
            a.description.toLocaleLowerCase() >
            b.description.toLocaleLowerCase()
              ? 1
              : -1
        );
        this.termsOfPaymentValue = [this.custom, ...this.termsOfPaymentValue];
      }
    });
    getAuxiliaryZtableValueByName(6).then((tableRegionNameValue) => {
      if (Object.keys(tableRegionNameValue).length > 0) {
        this.regionValue = tableRegionNameValue.ztables.sort((a, b) =>
          a.description.toLocaleLowerCase() > b.description.toLocaleLowerCase()
            ? 1
            : -1
        );
        this.regionValue = [this.custom, ...this.regionValue];
      }
    });
    let rowIndex = this.$store.state.supplier.rowIndex;
    if (rowIndex && this.supplierReportList.length > 0) {
      this.$refs[`row-${rowIndex}`].scrollIntoView({ block: "center" });
    }
    this.listSkeleton = false;
  },
  methods: {
    parseDateYYYYMMDD: parseDateYYYYMMDD,
    formatDateDDMMYYYY: formatDateDDMMYYYY,
    doubleClick(event, { item }) {
      const path = "supplier/editSupplier/" + item.id;
      this.accessRight.includes("edit") || this.accessRight.includes("show")
        ? window.open(path, "_blank")
        : "";
      this.$store.commit("supplier/SET_ROWINDEX", item.id);
    },
    newPagination(val) {
      this.pagination = val;
      this.numberOfRows = val.itemsLength;
    },
    updateHeader() {
      this.$store.commit(
        "supplierReport/SHOW_HEADERS",
        this.headers.filter((s) =>
          this.selectedHeaders.some((d) => d.value === s.value)
        )
      );
      this.selectedHeaders = this.showHeaders;
      this.dialog = false;
    },
    toggle() {
      this.$nextTick(() => {
        if (this.selectAllHeaders) {
          this.selectedHeaders = [];
        } else {
          this.selectedHeaders = this.headers.slice();
        }
      });
    },
    reset() {
      this.search = null;
      this.$store.commit("supplier/SET_SUPPLIER_REPORT", []);
      this.page = 1;
      this.limit = 10;
      this.limitData = this.limit;
    },
    resetFilters() {
      this.sortByValue = null;
      this.sortDescValue = null;
      this.selectedSupplier = null;
      this.selectedSupplierGroup = null;
      this.selectedRegion = null;
      this.selectedStatus = null;
      this.selectedPayment = null;
      this.$store.commit("supplier/SET_SUPPLIER_REPORT_QUERY", {});
      this.buildUrl();
    },
    downloadExcelFile() {
      let self = this;
      let data = self.supplierReportList.map((o) =>
        Object.fromEntries(
          self.selectedHeaders.map((k) => [
            k.text,
            k.value.includes(".")
              ? o[`${k.value.split(".")[0]}`] &&
                Object.keys(o[`${k.value.split(".")[0]}`]).length > 0
                ? o[`${k.value.split(".")[0]}`][`${k.value.split(".")[1]}`]
                : o[`${k.value.split(".")[0]}`]
              : o[`${k.value}`],
          ])
        )
      );
      data.forEach((el) => {
        for (let x in el) {
          if (!el[x]) {
            el[x] = "";
          }
        }
      });
      exportFromJSON({
        data: JSON.parse(JSON.stringify(data)),
        fileName: "supplierReport",
        exportType: exportFromJSON.types.xls,
      });
    },
    numberWithCommas(x) {
      return x
        ? parseFloat(x)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        : (0).toFixed(2);
    },
    buildUrl(val) {
      let obj = {};
      if (typeof val === "string" || typeof val === "object") {
        // this.page = 1;
        // obj.page = 1;
        // this.params.page = 1;
      }
      obj.search = this.search;
      obj.status = this.selectedStatus?.id;
      obj.supplierId = this.selectedSupplier?.id;
      obj.order_by = this.sortByValue;
      obj.sortDec = this.sortDescValue;
      obj.supplierGroupId = this.selectedSupplierGroup?.id;
      obj.termsOfPaymentId = this.selectedPayment?.id;
      obj.regionGroupId = this.selectedRegion?.id;
      obj = Object.entries(obj).reduce((acc, [key, val]) => {
        if (key === "supplierId" && val === null) return acc;
        else if (key === "supplierGroupId" && val === null) return acc;
        else if (key === "regionGroupId" && val === null) return acc;
        else if (key === "termsOfPaymentId" && val === null) return acc;
        else if (key === "order_by" && val === null) return acc;
        else if (key === "sortDec" && val === null) return acc;
        else if (key === "status" && val === null) return acc;
        return { ...acc, [key]: val };
      }, {});
      this.$store.commit("supplier/SET_SUPPLIER_REPORT_QUERY", obj);
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.supplierLimit += 10;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },
    async paginate() {
      const {
        supplier_group_id,
        selected_payment_id,
        region_group_id,
        selected_status_id,
        supplier_id,
      } = this.params;

      const payloadData = {
        where: supplier_id ? "supplier_id|" + supplier_id : undefined,
        whereAnd: supplier_group_id
          ? "group_id|" + supplier_group_id
          : undefined,
        where_and: "",
        empty: supplier_group_id ? "group_id|" + "NULL" : undefined,
        empty_1: region_group_id ? "suppliers.region_id|" + "NULL" : undefined,
        empty_2: selected_status_id
          ? "suppliers.status_id|" + "NULL"
          : undefined,
        empty_3: selected_payment_id
          ? "suppliers.payment_terms_id|" + "NULL"
          : undefined,
      };

      if (supplier_group_id && supplier_group_id === 111111111111111) {
        payloadData.empty, delete payloadData.whereAnd;
      } else {
        payloadData.whereAnd, delete payloadData.empty;
      }

      let where_and_prefix = "";
      let where_and_sufix = "";

      if (region_group_id && region_group_id !== 111111111111111) {
        (where_and_prefix += "suppliers.region_id,"),
          (where_and_sufix += region_group_id + ",");
        delete payloadData.empty_1;
      }

      if (selected_status_id && selected_status_id !== 111111111111111) {
        (where_and_prefix += "suppliers.status_id,"),
          (where_and_sufix += selected_status_id + ",");
        delete payloadData.empty_2;
      }

      if (selected_payment_id && selected_payment_id !== 111111111111111) {
        (where_and_prefix += "suppliers.payment_terms_id,"),
          (where_and_sufix += selected_payment_id + ",");
        delete payloadData.empty_3;
      }

      where_and_prefix = where_and_prefix.replace(/.$/, "|");
      where_and_sufix = where_and_sufix.replace(/.$/, "");

      payloadData.where_and = where_and_prefix + where_and_sufix;

      if (
        region_group_id &&
        region_group_id === 111111111111111 &&
        selected_status_id &&
        selected_status_id === 111111111111111 &&
        selected_payment_id &&
        selected_payment_id === 111111111111111
      ) {
        delete payloadData.where_and;
      }

      this.isProgressCircular = true;
      // end make params for where_and
      await this.$store
        .dispatch("supplier/GetSupplierReport", payloadData)
        .then(() => {
          this.isProgressCircular = false;
          this.$refs.search.focus();
        });
    },
  },
};
</script>

<style scoped>
::v-deep .v-pagination__item {
  font-size: 12px;
}
::v-deep .custom-table ::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}
</style>
